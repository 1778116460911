.vanta {
  min-height: 300px;
  height: 100vh;
  width: 100vw;
  color: rgb(255, 255, 255);
  padding: 5rem 10rem;
  font-size: 24px;
  position: flex;


}

.vantaAjust {
  margin-top: 0;
}


@media (max-width: 640px) {
  .vanta {
    padding: 0;
  }

  /* .vantaAjust {
    margin-top: -3rem;
  } */
}

